import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
// import Title from "../components/title"
import Inview from "../components/inview";
const CumBuckle = ({ data }) => {
  const pageData = {
    titleClass: "cum-buckle",
    title: "25mm BELT",
    discription:
      "パレットの固縛や倉庫内作業における簡易固定、棚からの落下防止等に多用されています。安価でもしっかり荷物を固定できる優れものの荷締めベルト。",
    image: 12,
  };
  const seodata = {
    title:
      "誰でも簡単・手軽に使える、25mm幅ベルト [25mm BELT CUM BUCKLE] アヴィエラン株式会社",
    seo: {
      description:
        "パレットの固縛や倉庫内作業における簡易固定、棚からの落下防止等に多用されています。安価でもしっかり荷物を固定できる優れものの荷締めベルト。",
      image: {
        url: data.datoCmsImage.topimage[12].url,
      },
    },
    slug: "cum-buckle",
  };
  //   const newsData = data.allDatoCmsNews.nodes
  const imageData = data.datoCmsImage;
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <div className="pagetitle lashingbelt">
        <div className="fixedtitleimg productimg roundimg">
          <Img
            fluid={imageData.productsImg[10].item}
            alt="誰でも簡単・手軽に使える、25mm幅ベルト"
          />
        </div>
        <div className="fixedtexttitle">
          <h1>25mm BELT</h1>
        </div>
      </div>
      <div className="pankz">
        <Link to="/">トップ</Link>
        <Link to="/logisticsequipment/">物流機器事業本部</Link>
        <p>25mm BELT</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[32].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              誰でも簡単・手軽に使える、25㎜幅ベルト
              <span>A soft hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              パレットの固縛や倉庫内作業における簡易固定、棚からの落下防止等に多用されています。安価でもしっかり荷物を固定できる優れものの荷締めベルト。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[0].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              カムバックルベルト エンドレスタイプ
              <span>Cumbuckle endless type</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ワンタッチでベルトの調整ができるカムバックルを採用。
              パレットの固縛や倉庫内作業における簡易固定、棚からの落下防止等に使用されているエンドレスタイプ。
              ポリエステル原糸を100％使用している為伸び率が低く、耐久性に優れている為、高い安全性を持ち合わせています。
            </Inview>
            <a
              href="https://www.slingbelt.com/collections/types?q=cambucklebelt"
              target="_blank"
              rel="noreferrer"
            >
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[33].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              サインベルト ®<span>Sign belt</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              サインベルトには、ひと目で注意・警
              告レベルの判断がつくよう、禁止事項や警告事項をアイコン化、インパクトのある警告・禁止文字を併記することで、作業現場の注意喚起を図ります。
              また、ベルトのベースカラーを変え、4つのカラーバリエーションを設けることで危険度のレベルを表現しています。
            </Inview>
            <a
              href="https://www.slingbelt.com/collections/signbeltendless"
              target="_blank"
              rel="noreferrer"
            >
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[35].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              タイダウンベルト
              <span>Tie down belt</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              車にオートバイ・耕耘機・バイクを固定するのに最適。
              車にオートバイ・耕耘機・バイク等を載せるだけでは不安定なので、ワンタッチでベルトの張力を調整して簡単に固定することが出来ます。
            </Inview>
            <a
              href="https://www.slingbelt.com/collections/types?q=tiedown"
              target="_blank"
              rel="noreferrer"
            >
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[34].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              サブベルト
              <span>Sub belt</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              タイダウンベルトのフックに通して使用することで対象物(オートバイ・耕耘機・バイク)を固縛します。
              対象物に直接フックが掛けられない時やフックによる傷の防止に役立ちます。
              一度ひねりを入れて縫製をしているためベルトを棒などにかけやすくなっております。
            </Inview>
            <a
              href="https://www.slingbelt.com/collections/types?q=subbelt"
              target="_blank"
              rel="noreferrer"
            >
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img
              fluid={data.datoCmsImage.productsImg[36].item}
              alt="誰でも簡単・手軽に使える、25㎜幅ベルト"
            />
            <Inview c_name="title" tag="h2">
              一斗缶取っ手！とってちゃん
              <span>Itto-kan handle! Tote-chan</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              一斗缶を持ち運ぶ際に手に痛みを感じることはないでしょうか？
              『とってちゃん』は一斗缶にポリエステル製のベルトを装着する事で、運搬を補助します。
              作業者が持つ取っ手部分が面で掌にフィットするクッション素材になっており、運搬の際の持ち手への負担を大幅に軽減する事が出来ます。
              <br />
              ※一斗缶は付属しておりません。
            </Inview>
            <a
              href="https://www.slingbelt.com/collections/types?q=tottechan"
              target="_blank"
              rel="noreferrer"
            >
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CumBuckle;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        item: fluid(
          maxWidth: 1200
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
